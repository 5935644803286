<template>
  <!-- 保险>幼儿校责险出险 -->
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">员工校责险出险</div>
      <div>
        <el-dropdown @command="handleCommand">
          <el-button type="primary" style="margin-right: 0.52083vw">
            导出 <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="company">按分公司</el-dropdown-item>
            <el-dropdown-item command="school">按园校</el-dropdown-item>
            <el-dropdown-item command="all">全部</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          type="primary"
          class="sheet-btn"
          @click="showFile"
          v-if="isViewPolicy"
        >
          查看保单
        </el-button>

        <el-button
          type="primary"
          class="sheet-btn"
          @click="onCreatChart"
          v-if="isChart"
        >
          生成图表
        </el-button>
      </div>
    </div>
    <div>
      <!-- 表单 -->
      <el-form
        :model="form"
        onsubmit="return false"
        :inline="true"
        class="search-form"
      >
        <el-form-item>
          <el-select
            v-model="form.orgId"
            clearable
            placeholder="请选择分公司"
            @change="handleCompany"
          >
            <el-option
              v-for="item in moduleCompanyOptions"
              :key="item.label"
              :label="item.name"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select
            v-model="form.parkId"
            clearable
            placeholder="请选择园校"
            :disabled="disabledPark"
          >
            <el-option
              v-for="item in moduleParkOptions"
              :key="item.label"
              :label="item.name"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.injuryType"
            clearable
            placeholder="选择伤害类别"
            class="select-condition"
          >
            <el-option
              v-for="item in dictList.injury_type"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.week"
            clearable
            placeholder="选择星期"
            class="select-condition"
          >
            <el-option
              v-for="item in dictList.danger_week"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.accidentLocation"
            clearable
            placeholder="选择事件地点"
            class="select-condition"
          >
            <el-option
              v-for="item in dictList.accident_location"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            v-model="form.accidentTime"
            type="daterange"
            range-separator="～"
            value-format="yyyy-MM-dd"
            start-placeholder="事件开始日期"
            end-placeholder="事件结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-select
            v-model="form.link"
            clearable
            placeholder="选择环节"
            class="select-condition"
          >
            <el-option
              v-for="item in dictList.danger_link"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.isClosed"
            clearable
            placeholder="选择是否结案"
            class="select-condition"
          >
            <el-option
              v-for="item in is_closed"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input
            v-model="form.beginAmount"
            placeholder="医疗金额"
            clearable
            class="select-condition"
          />
          —
          <el-input
            v-model="form.endAmount"
            placeholder="医疗金额"
            clearable
            class="select-condition"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="searchFun">
            查询
          </el-button>
        </el-form-item>

        <el-form-item>
          <el-button
            @click="resetFun"
            icon="el-icon-refresh-left"
            class="reset"
          >
            重置
          </el-button>
        </el-form-item>
      </el-form>

      <!-- 表格 -->
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: '#F1F4F9',
          color: '#5E6D9B',
          fontWeight: '400',
          'text-align': 'center',
        }"
        v-loading="tableLoading"
      >
        <el-table-column
          type="index"
          label="序号"
          min-width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orgName"
          label="分公司"
          min-width="150"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-tooltip
              effect="light"
              popper-class="customPopper"
              :content="row.orgName"
              placement="top"
              v-if="row.orgName"
            >
              <div class="description-div">
                {{ row.orgName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="parkName"
          label="园校"
          min-width="200"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-tooltip
              effect="light"
              popper-class="customPopper"
              :content="row.parkName"
              placement="top"
              v-if="row.parkName"
            >
              <div class="description-div">
                {{ row.parkName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="insuredPerson"
          label="姓名"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="injuryType"
          label="伤害类别"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="accidentTime"
          label="事故日期"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="week"
          label="星期"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="accidentLocation"
          label="事故地点"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="link"
          label="环节"
          min-width="90"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="mainResponsibility"
          label="主责"
          min-width="90"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="isClosed"
          label="是否结案"
          min-width="100"
          align="center"
        >
          <template slot-scope="{ row }">
            <span :class="row.isClosed == 0 ? 'activeColor' : 'defaultColor'">
              {{ row.isClosed == 0 ? "否" : "是" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="medicalExpenses"
          label="医疗金额"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="isAdditionalCompensation"
          label="是否额外赔偿"
          min-width="120"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>
              {{ row.isAdditionalCompensation == 0 ? "否" : "是" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="isClaimSettlement"
          label="是否已理赔"
          min-width="100"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>
              {{ row.isClaimSettlement == 0 ? "否" : "是" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="claimTime"
          label="理赔时间"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="claimAmount"
          label="理赔金额"
          min-width="120"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="remarks"
          label="备注"
          width="180"
        ></el-table-column> -->
        <el-table-column
          prop="remarks"
          label="备注"
          min-width="180"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="light"
              popper-class="customPopper"
              :content="row.remarks"
              placement="top"
              v-if="row.remarks"
            >
              <div class="description-div">{{ row.remarks }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          min-width="130"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              @click="handleJumpDetail(scope.row)"
              type="text"
              size="medium"
              v-if="isView"
              >查看</el-button
            >
            <el-popconfirm
              style="margin: 0 0.52083vw"
              @confirm="delDirectory(scope.row)"
              title="删除后将不能恢复，确定删除吗？"
            >
              <el-button
                type="text"
                size="medium"
                slot="reference"
                v-if="isDel"
              >
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="manger-footer">
        <div class="footer-pagination">
          <el-pagination
            background
            @size-change="handleSizeChange"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.total"
            :page-size="pagination.size"
            @current-change="handleCurrentChangePage"
            :current-page.sync="pagination.currentPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <onlinePreview
      v-if="attachmentFilelist.length > 0"
      :onlinePreviewVisible="onlinePreviewVisible"
      :type="fileAttachment.previewType"
      @closeOnlinePreviewDialog="closeOnlinePreviewDialog"
      :url="fileAttachment.url"
      :title="attachmentFilelist[0].attachmentName || ''"
    />
  </div>
</template>

<script>
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
import downloadFile from "@/utils/downloadFile";
import onlinePreview from "@/components/onlinePreview/index";
export default {
  data() {
    return {
      form: {
        orgId: "",
        parkId: "",
        injuryType: "",
        week: "",
        accidentLocation: "",
        accidentTime: "",
        link: "",
        isClosed: "",
        beginAmount: "",
        endAmount: "",
      },
      is_closed: [
        { name: "是", value: "1" },
        { name: "否", value: "0" },
      ], // 操作类型下拉
      moduleCompanyOptions: [], //所属公司下拉
      moduleParkOptions: [], //所属园校下拉
      disabledPark: true,
      tableData: [],
      tableNotinsure: [], //未投保
      pagination: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        size: 10, // 每页显示多少条
      },

      tableLoading: false, // 表格loading
      tabClickIndex: "",
      fileAttachment: {},
      dictList: {
        danger_week: [],
        danger_link: [],
        accident_location: [],
        injury_type: [],
      },
      onlinePreviewVisible: false,
      attachmentFilelist: [],
      permissionButtonList: [], // 权限按钮list
      pickerOptions: {
        disabledDate(times) {
          return times.getTime() > Date.now();
        },
      },
      isView: false,
      isExport: false,
      isViewPolicy: false,
      isChart: false,
      isDel: false,
    };
  },

  components: {
    onlinePreview,
  },

  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
    }),
  },

  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isView = val.indexOf("z_employeeOutinsurance:view") != -1;
          this.isExport = val.indexOf("z_employeeOutinsurance:export") != -1;
          this.isViewPolicy =
            val.indexOf("z_employeeOutinsurance:viewPolicy") != -1;
          this.isChart = val.indexOf("z_employeeOutinsurance:chart") != -1;
          this.isDel = val.indexOf("z_employeeOutinsurance:del") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },

  created() {
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },

  mounted() {
    // this.tableLoading = true;
    // this.init();
    this.getCompanyDeptList();
    this.queryDict();
    this.getAttachmentPage();
  },
  activated() {
    this.tableLoading = true;
    this.init();
  },

  methods: {
    getPermissionButton,
    //字典查询
    queryDict() {
      var dict = Object.keys(this.dictList);
      let requests = dict.map(
        (item) =>
          new Promise((resolve, reject) => {
            this.$api
              .getDictionary({ type: item })
              .then((res) => {
                res.data.fieldName = item;
                resolve(res.data);
              })
              .catch(() => {
                resolve([]);
              });
          })
      );
      Promise.all(requests).then((res) => {
        res.forEach((item) => {
          this.dictList[item.fieldName] = item.data;
        });
      });
    },

    /** 初始化 */
    init() {
      let params = {
        type: "2",
        orgId: this.form.orgId,
        parkId: this.form.parkId,
        injuryType: this.form.injuryType,
        week: this.form.week,
        accidentLocation: this.form.accidentLocation,
        link: this.form.link,
        isClosed: this.form.isClosed,
        beginAmount: this.form.beginAmount,
        endAmount: this.form.endAmount,
        beginTime: this.form.accidentTime ? this.form.accidentTime[0] : "",
        endTime: this.form.accidentTime ? this.form.accidentTime[1] : "",
        current: this.pagination.currentPage,
        size: this.pagination.size,
      };

      this.$api.getPayPage(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询分公司
    getCompanyDeptList() {
      let params = {
        type: "NODE_ORG",
      };
      this.$api.getCompanyDept(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleCompanyOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询分公司change事件
    handleCompany(val) {
      this.form.parkId = "";

      if (val == "") {
        this.disabledPark = true;
      } else {
        this.disabledPark = false;
        this.getSchoolList(val);
      }
    },

    //查询园校
    getSchoolList(valCompany) {
      let params = {
        parent: valCompany,
        type: "NODE_SCHOOL",
      };
      this.$api.getCompanyDept(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleParkOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 按类型导出
    handleCommand(type) {
      if (type == "company") {
        this.downLoadFile(1);
      } else if (type == "school") {
        this.downLoadFile(2);
      } else if (type == "all") {
        this.downLoadFile(3);
      }
    },
    /** 导出 */
    downLoadFile(downFlag) {
      if (this.tableData.length > 0) {
        //下载文件
        let url = "/mng/insurance/pay/export";

        let data = {
          type: "2",
          orgId: this.form.orgId,
          parkId: this.form.parkId,
          injuryType: this.form.injuryType,
          week: this.form.week,
          accidentLocation: this.form.accidentLocation,
          link: this.form.link,
          isClosed: this.form.isClosed,
          beginAmount: this.form.beginAmount,
          endAmount: this.form.endAmount,
          beginTime: this.form.accidentTime[0],
          endTime: this.form.accidentTime[1],
          downFlag,
        };

        let type = "get";

        this.tableLoading = true;
        downloadFile({ url, data, type }).then(() => {
          this.tableLoading = false;
        });
      } else {
        this.$message.warning("暂无数据，不能导出！");
      }
    },

    //查看保单pdfpath
    getAttachmentPage() {
      let params = {
        type: 2,
      };
      this.$api.getAttachmentPage(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.attachmentFilelist = res.data.data.records;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查看文件
    showFile() {
      if (this.attachmentFilelist.length > 0) {
        if (this.attachmentFilelist[0].pdfPath) {
          let url = "mng/insurance/attachment/downloadFile";
          let data = {
            id: this.attachmentFilelist[0].attachmentId,
            type: 2,
            flag: 1,
          };
          let type = "get";
          this.onlinePreviewVisible = true;
          this.fileAttachment = {
            previewType: "pdf",
            url: this.attachmentFilelist[0].pdfPath,
            attachment_name: this.attachmentFilelist[0].attachment_name,
            attachment_type: this.attachmentFilelist[0].attachment_type,
          };
        } else {
          this.$message.error("暂不支持在线预览，请下载后查看");
          return;
        }
      } else {
        this.$message.warning("请上传后查看保单！");
      }
    },

    // 查询
    searchFun() {
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    // 重置
    resetFun() {
      this.form = {
        orgId: "",
        parkId: "",
        policyholder: "",
        injuryType: "",
        week: "",
        accidentLocation: "",
        accidentTime: "",
        link: "",
        isClosed: "",
        beginAmount: "",
        endAmount: "",
      };

      this.disabledPark = true;
      this.pagination.currentPage = 1;
      this.tableLoading = true;
      this.init();
    },

    /** 生成图表 */
    onCreatChart() {
      this.$router.push({
        path: "/insuranceBringcharts",
        query: {
          type: "2",
          orgId: this.form.orgId,
          parkId: this.form.parkId,
          injuryType: this.form.injuryType,
          week: this.form.week,
          accidentLocation: this.form.accidentLocation,
          link: this.form.link,
          isClosed: this.form.isClosed,
          beginAmount: this.form.beginAmount,
          endAmount: this.form.endAmount,
          beginTime: this.form.accidentTime[0],
          endTime: this.form.accidentTime[1],
          childChart: 2,
        },
      });
    },

    //查看
    handleJumpDetail(row) {
      this.$router.push({
        path: "/lookAdd",
        query: {
          lookId: row.id,
          newAdd: "0",
          typeE: "2",
        },
      });
    },

    //删除
    delDirectory(row) {
      let params = {
        id: row.id,
      };
      this.$api.getPayRemove(params).then((res) => {
        console.log(res, "查看ye");
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.$message.success(res.data.data);
          this.init();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    closeOnlinePreviewDialog() {
      this.fileAttachment = {};
      this.onlinePreviewVisible = false;
    },

    /** 分页 */
    handleCurrentChangePage(val) {
      this.pagination.currentPage = val;
      this.tableLoading = true;
      this.init();
    },
    handleSizeChange(val) {
      this.pagination.size = val;
      this.tableLoading = true;
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

.contain {
  // width: calc(100% - 40px);
  background-color: #ffffff;
  padding: 26px 30px 30px 30px;
  // box-sizing: border-box;

  /deep/.el-tabs__header {
    margin: 0 0 30px;
  }

  /deep/.el-tabs--border-card {
    background: #fff;
    border: none;
    box-shadow: none;
  }

  /deep/.el-tabs--border-card > .el-tabs__header {
    background-color: transparent;
    border-bottom: none;
    margin: 0;
  }

  /deep/.el-tabs__item {
    font-size: 16px;
  }

  /deep/.el-tabs__item:hover {
    color: #2a00b2;
    cursor: pointer;
  }

  /deep/.el-tabs__item.is-active {
    color: #2a00b2;
  }

  /deep/.el-tabs__active-bar {
    background-color: #2a00b2;
  }

  .noinsure-title {
    color: #606266;
    text-align: center;
    line-height: 50px;
    border: 1px solid #ebeef5;
  }

  .form-btn {
    display: flex;
    margin: 20px 0;

    .sheet-btn {
      background: #2a00b2;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }

  .manger-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 1px;
  }

  .description-div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
